.header {
  display: flex;
  height: 0;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  @media screen and (max-width: 960px) {
    align-items: center;
    background-color: $white;
    background-image: url('../../design/RZ_JPGS/Winkler_Web_Hintergrund_2cremé.jpg');
    height: auto;
  }
}

.menu {
  background-color: $menu-bg;
  background-image: url('../../design/RZ_JPGS/Winkler_webseite_Hintergrund_5dunkelbraunmit Stern.jpg');
  background-position: bottom right;
  background-size: cover;
  height: 100vh;
  left: -9999em;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity $menu-fade-in-duration ease, left 0s $menu-fade-in-duration;
  width: 100%;
  z-index: -1;
}

.burger {
  align-items: center;
  color: $brown;
  cursor: pointer;
  display: flex;
  font: inherit;
  margin: 2.8em 2.3em 0;
  padding: .5em;
  transition: color .15s ease;

  &:hover {
    opacity: .7;
  }

  &:focus {
    outline: none;
  }

  @media screen and (max-width: 52.5em) {
    margin: 1em 1em 0;
  }
}

.burger-wrapper {
  display: inline-block;
  height: 1.1em;
  position: relative;
  width: 1.8em;
}

.burger-parts {
  display: block;

  &,
  &::before,
  &::after {
    background-color: $brown;
    border-radius: .2em;
    height: .24em;
    position: absolute;
    //transition: transform .15s ease, opacity .15s ease;
    transition: transform .15s ease, background .15s ease;
    width: 1.8em;
  }

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    top: .49em;
  }

  &::after {
    top: .98em;
  }
}

.show-menu {
  display: none;

  &:checked ~ .menu {
    left: 0;
    opacity: 1;
    transition: opacity $menu-fade-in-duration ease;
  }

  &:checked + .burger {
    .burger-parts {
      background-color: $beige;
      //transform: translate3d(0, .625em, 0) rotate(45deg);
      background: transparent;
      height: .12em;

      &::before {
        //background-color: $beige;
        //opacity: 0;
        //transform: rotate(-45deg) translate3d(-.4375em, -.4375em, 0);

        transform: scale(15);
        background: url('../../design/img/stern-b.svg');
        background-size: cover;
        height: .12em;
        width: .12em;
        left: 50%;
        border-radius: 0;
      }

      &::after {
        background-color: $beige;
        //transform: translate3d(0, -1em, 0) rotate(-90deg);
        background: transparent;
        height: .12em;
      }
    }
  }
}

.logo {
  margin-left: 2.3em;
  margin-top: 2.3em;
  width: 14em;

  a {
    display: inline-block;
  }

  @media screen and (max-width: 52.5em) {
    margin-left: 1em;
    margin-top: 1em;
  }

  @media screen and (max-width: 62.5em) {
    max-width: 25vw;
  }
}

.menu-title {
  @include kanit-extra-light;
  font-size: 1.2em;
  margin-right: .25em;
  text-transform: uppercase;
}

.burger--light {
  color: $light;

  .burger-parts {
    &,
    &::before,
    &::after {
      background-color: $light;
    }
  }
}

.logo-fill {
  transition: fill .15s ease;
}

.logo--darker {
  .logo-fill {
    fill: $dark-logo;
  }
}
