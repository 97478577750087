/// @Author: Manuel Weber <mail@weber-manuel.net>
/// @Date:   2020-10-23
/// @Last Modified by:   Manuel Weber <mail@weber-manuel.net>
/// @Last Modified time: 2020-10-23

html {
  box-sizing: border-box;
}

* {
  &,
  ::before,
  ::after {
    box-sizing: inherit;
  }
}
