@mixin kanit-regular {
  font-family: Kanit, Helvetica, sans-serif;
  font-size: 1.06em;
  font-weight: 400;
  letter-spacing: .06em;
  line-height: 140%;
 }

@mixin kanit-light {
  font-family: Kanit, Helvetica, sans-serif;
  font-size: 1.06em;
  font-weight: 300;
  letter-spacing: .06em;
  line-height: 140%;
 }

@mixin kanit-extra-light {
  font-family: Kanit, Helvetica, sans-serif;
  font-size: 2em;
  font-weight: 200;
  letter-spacing: .1em;
  line-height: 100%;
 }

@mixin cardo-bold {
  font-family: Cardo, serif;
  font-size: 2.4em;
  font-weight: 700;
  letter-spacing: .06em;
  line-height: 100%;
}

@mixin cardo-bold-inline {
  font-family: Cardo, serif;
  font-weight: 700;
  letter-spacing: .06em;
}

@mixin kanit-extra-light-inline {
  font-family: Kanit, Helvetica, sans-serif;
  font-weight: 200;
  letter-spacing: .1em;
  line-height: 100%;
}
