body {
  @include kanit-light;

  scrollbar-color: $beige $light;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: $light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $beige;
    border-radius: 6px;
    border: 3px solid $light;
  }
}

@for $i from 1 through 6 {
  h#{$i} {
    margin: 0;
    padding: 0;
  }
}

.light {
  color: $light;
}

.link,
.link-small,
.email {
  border-bottom: .2em solid $brown;
  color: $brown;
  text-decoration: none;
}

.link {
  text-transform: uppercase;
}

.inline-title {
  @include cardo-bold-inline;
  display: block;
  font-size: 1.2em;
  margin-top: .7em;
  text-transform: uppercase;
}

.anchor {
  position: relative;
  top: -1em;
  visibility: hidden;
}

@media screen and (max-width: 960px) {
  .anchor {
    top: -12em;
  }
}
