.form-text,
.form-textarea {
  margin-bottom: 1em;

  label,
  input,
  textarea {
    display: block;
  }

  label {
    text-transform: uppercase;
  }

  input {
    max-width: 18em;
  }

  textarea {
    height: 6em;
  }

  input,
  textarea {
    border: 0;
    font: inherit;
    outline: 2px solid transparent;
    transition: color .2s ease, outline-color .2s ease;
    width: 100%;

    &:active,
    &:hover,
    &:focus {
      color: $brown;
      outline: 2px solid $brown;
    }
  }
}

.form-button {
  button {
    background: none;
    border: 0;
    border-bottom: .2em solid $brown;
    color: $brown;
    cursor: pointer;
    font-size: 1.25em;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    transition: all .15s ease;

    &:hover {
      border-color: $beige;
      color: $beige;
    }
  }
}

.contact-form {
  margin: auto;
  max-width: 30em;

  .form-text {
    input {
      max-width: initial;
    }
  }
}

.contact-error {
  color: $danger;
  font-size: .7em;
  text-transform: uppercase;
}

.form-url {
  display: none;
}
