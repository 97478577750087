.menu-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  padding-top: $header-height;
  scrollbar-color: $menu-bg $light;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: $light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $menu-bg;
    border-radius: 6px;
    border: 3px solid $light;
  }
}

.main-menu {
  align-items: center;
  align-self: center;
  display: flex;
  flex: 1;

  ul {
    list-style-type: none;
    margin: 2.5em;
    padding: 0;
  }

  li {
    @include cardo-bold;
    padding: .2em 0;

    @media screen and (max-width: 52.5em) {
      font-size: 4.5vw;
    }
  }

  a {
    align-items: center;
    color: $black;
    display: flex;
    padding: .2em;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;

    &:hover,
    &.active {
      color: $light;

      &::before {
        background-image: url('../../design/img/stern.svg');
        background-size: cover;
        content: '';
        display: block;
        height: 1em;
        left: -1.5em;
        position: absolute;
        width: 1em;
      }
    }
  }
}

.footer-menu,
.fp-menu-dark {
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    margin: 0;
    padding: 0;

    @media screen and (max-width: 52.5em) {
      justify-content: center;
      font-size: .75em;
    }
  }

  li {
    @include kanit-light;
    font-size: 1em;
    margin: .5em;
  }

  a {
    display: inline-block;
    padding: .5em;
    text-decoration: none;
    text-transform: uppercase;

    &::after {
      content: '';
      display: block;
      height: 1px;
      transform: scaleX(0);
      transition: transform .2s ease;
    }

    &:hover {
      &::after {
        transform: scaleX(1);
    }
  }
  }
}

.footer-menu {
  a {
    color: $light;

    &::after {
      background: $light;
    }
  }
}

.fp-menu-dark {
  a {
    color: $black;

    &::after {
      background: $black;
    }
  }
}

.fp-menu,
.fp-menu-dark {
  align-items: flex-end;
  display: flex;
  flex: 1;
  width: 100%;
}
