//.main {
//  padding-top: $header-height;
//}

.full-page {
  align-items: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 6em;

  @media screen and (max-width: 52.5em) {
    padding: 2em;
  }
}

.start-page {
  padding-bottom: 0;
  padding-top: $header-height;
}

.fp-img {
  background-attachment: fixed;
  background-color: $beige;
}

.fp-star {
  background-position: bottom right;
}

.fp-star-white {
  background-color: $white;
  background-image: url('../../design/RZ_JPGS/Winkler_webseite_Hintergrund_1weißmit Stern.jpg');
}

.fp-star-creme {
  background-color: $white;
  background-image: url('../../design/RZ_JPGS/Winkler_webseite_Hintergrund_2cremémit Stern.jpg');
}

.fp-star-light-brown {
  background-color: $beige;
  background-image: url('../../design/RZ_JPGS/Winkler_webseite_Hintergrund_3hellbraunmit Stern.jpg');
}

.fp-star-mid-brown {
  background-color: $beige;
  background-image: url('../../design/RZ_JPGS/Winkler_webseite_Hintergrund_4mittelbraunmit Stern.jpg');
}

.fp-star-brown {
  background-color: $brown;
  background-image: url('../../design/RZ_JPGS/Winkler_webseite_Hintergrund_5dunkelbraunmit Stern.jpg');
}


// Weingut Bilder
.fp-img-1 {
  background-image: url('../../design/RZ_JPGS/Winkler_Web_1_Weingut.jpg');
}

.fp-img-2 {
  background-image: url('../../design/RZ_JPGS/Winkler_Web_2_oekologischerWeinbau.jpg');
}

.fp-img-3 {
  background-image: url('../../design/RZ_JPGS/Winkler_Web_3_Keller.jpg');
}

.fp-img-4 {
  background-image: url('../../design/RZ_JPGS/Winkler_Web_4_Vermarktung.jpg');
}

// Weine Bilder
.fp-img-5 {
  background-image: url('../../design/RZ_JPGS/Winkler_Web_5_Weine.jpg');
}

.fp-img-6 {
  background-image: url('../../design/RZ_JPGS/Winkler_Web_6_Echt.jpg');
}

.fp-img-7 {
  background-image: url('../../design/RZ_JPGS/Winkler_Web_7_Ehrlich.jpg');
}

.fp-img-8 {
  background-image: url('../../design/RZ_JPGS/Winkler_Web_8_Einzigartig.jpg');
}

.fp-img-9 {
  background-image: url('../../design/RZ_JPGS/Winkler_Web_9_Weinuebersicht.jpg');
}

// Bei uns zu Gast Bilder
.fp-img-10 {
  background-image: url('../../design/RZ_JPGS/Winkler_Web_10_BeiunszuGast_abHof.jpg');
}

.fp-img-11 {
  background-image: url('../../design/RZ_JPGS/Winkler_Web_11_BeiunszuGast_Winkler.jpg');
}

.fp-img-12 {
  background-image: url('../../design/RZ_JPGS/Winkler_Web_12_BeiunszuGast_Klein.jpg');
}

// Veranstaltungen Bilder
.fp-img-13 {
  background-image: url('../../design/RZ_JPGS/Winkler_Web_13_Veranstaltungen.jpg');
}

.fp-img-14 {
  background-image: url('../../design/RZ_JPGS/Winkler_Web_14_Endbildvor Shop.jpg');
}

// Shop
.fp-img-15 {
  background-image: url('../../design/RZ_JPGS/Winkler_Web_15_Shopeinleitung.jpg');
}

.main-heading {
  @include cardo-bold;
  font-size: 7em;
  text-align: center;
  text-transform: uppercase;

  span {
    @include kanit-extra-light;
    display: block;
    font-size: .38em;
  }

  @media screen and (max-width: 94em) {
    font-size: 8vw;
  }
}

.sub-heading {
  @include cardo-bold;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  text-transform: uppercase;

  .subline {
    @include kanit-extra-light;
    font-size: .75em;
  }

  @media screen and (max-width: 52.5em) {
    font-size: 1.8em;
  }
}

.content {
  margin: 1em;
  max-width: 520px;
  width: 100%;

  &.static-page {
    max-width: 75%;
  }

  p {
    font-size: 1.2em;
    line-height: 140%;

    @media screen and (max-width: 52.5em) {
      font-size: 1em;
    }
  }
}

.content-wide {
  max-width: 720px;
}

.content-extra-wide {
  max-width: 860px;
}

.star,
.star-light,
.star-subline {
  position: relative;

  &::before {
    background-size: cover;
    content: '';
    height: .7em;
    width: .7em;
  }
}

.star,
.star-light {
  &::before {
    left: -1em;
    position: absolute;
    top: 0;
  }
}

.star-subline {
  display: flex;
  flex-direction: row;

  &::before {
    margin-right: .5em;
  }
}

.star,
.star-subline {
  &::before {
    background-image: url('../../design/img/stern-d.svg');
  }
}

.star-light {
  &::before {
    background-image: url('../../design/img/stern.svg');
  }
}

.star-top {
  position: relative;

  &::before {
    background-size: cover;
    content: '';
    height: .7em;
    left: -1em;
    position: absolute;
    top: -1.2em;
  }
}

.star-top-1 {
  &::before {
    background-image: url('../../design/img/stern-d.svg');
    height: .7em;
    width: .7em;
  }
}

.star-top-2 {
  &::before {
    background-image: url('../../design/img/stern-d-2.svg');
    height: .7em;
    width: 1.66em;
  }
}

.star-top-3 {
  &::before {
    background-image: url('../../design/img/stern-d-3.svg');
    height: .7em;
    width: 2.6em;
  }
}

.star-top-1-l {
  &::before {
    background-image: url('../../design/img/stern.svg');
    height: .7em;
    width: .7em;
  }
}

.star-top-2-l {
  &::before {
    background-image: url('../../design/img/stern-2.svg');
    height: .7em;
    width: 1.66em;
  }
}

.star-top-3-l {
  &::before {
    background-image: url('../../design/img/stern-3.svg');
    height: .7em;
    width: 2.6em;
  }
}

.figure {
  padding: 3em 2.5em 0;
}

.figure-wrapper {
  display: flex;

  &::after {
    content: '';
    flex: 1;
  }
}

.figure-img {
  flex: 1;
  max-height: 40em;
  text-align: right;

  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.shop-preview {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 800px) {
    flex-wrap: wrap;
  }
}

.shop-preview-item {
  padding: 0 4em;
  width: 33.3333333%;

  @media screen and (max-width: 800px) {
    padding: 0 2em;
    width: 50%;
  }
}

.shop-preview-img {
  img {
    display: block;
    max-height: 25em;
    min-width: 2em;
    max-width: 100%
  }
}

.shop-preview-desc {
  font-size: .5em;
  margin-top: 6em;

  .subline {
    font-size: .9em;
    font-weight: 300;
    line-height: 160%;
    text-transform: none;
  }
}

.shop-preview-meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1em;

  a {
    border-bottom: .12em solid $light;
    color: $light;
    display: inline-block;
    font-size: 2em;
    padding-bottom: .25em;
    text-decoration: none;
    text-transform: uppercase;
  }

  img {
    display: block;
    width: 3.5em;
  }
}

.no-events {
  background: $brown;
  color: $light;
  display: none;
  padding: .5em;
  text-transform: uppercase;
}

.phone {
  color: inherit;
  text-decoration: none;

  span {
    @include cardo-bold-inline;
    color: $brown;
    text-transform: uppercase;
  }
}

.pricing {
  display: flex;
  flex-wrap: wrap;
  margin-left: -1.5em;
  opacity: 0;
  transition: opacity .2s ease;

  > div {
    flex: 1;
    margin: 0 0 1.5em 1.5em;
  }
}

.address {
  h4 {
    @include kanit-extra-light-inline;
    font-size: 1.5em;
    text-transform: uppercase;

    span {
      @include cardo-bold-inline;
    }
  }
}

.address-contact {
  line-height: 180%;
  margin-bottom: 3em;

  h5 {
    @include kanit-extra-light-inline;
    font-size: 1.5em;
    text-transform: uppercase;

    span {
      @include cardo-bold-inline;
    }
  }
}

.price-list {
  list-style: none;
  margin: 0 0 0 1.2em;
  padding: 0;

  > li {

    &::before {
      background-image: url('../../design/img/stern-d.svg');
    }
  }

  li {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &::before {
      align-self: center;
      background-size: cover;
      content: '';
      display: inline-block;
      height: .6em;
      left: -1.2em;
      position: absolute;
      top: .25em;
      width: .6em;
    }

    span {
      flex: 1 0 auto;
      white-space: nowrap;
    }

    span + span {
      @include cardo-bold-inline;
      color: $brown;
      font-size: .9em;
      text-align: right;
    }

    ul {
      flex-basis: 100%;

      li {
        &::before {
          content: '-';
          top: 0;
        }
      }
    }
  }
}

.price-additional {
  margin: 1em 0 0 1.2em;
}

.menu--contrast {
  background: white;
}

.static-headline {
  @include kanit-extra-light-inline;
  margin-bottom: 1em;
  max-width: 75%;
  text-transform: uppercase;
  width: 100%;
}

.static-page {
  h2,
  h3,
  h4 {
    @include cardo-bold-inline;
    color: $brown;
    line-height: 140%;
    margin-bottom: 1em;
  }

  ul {
    font-size: 1.2em;
    line-height: 140%;

    @media screen and (max-width: 52.5em) {
      font-size: 1em;
    }
  }

  p {
    a {
      color: inherit;
      transition: color .2s ease;

      &:hover,
      &:active,
      &:focus {
        color: $brown;
      }
    }
  }
}

.terms-shipping,
.terms-date {
  @include kanit-extra-light-inline;
}

.terms-date {
  font-size: .7em;
  margin-top: 5em;
  text-transform: uppercase;
}

.terms-address {
  display: flex;
  flex-wrap: wrap;
  font-size: .7em;
  margin-top: 5em;

  h5 {
    @include kanit-extra-light-inline;
    align-items: center;
    display: flex;
    font-size: 1.5em;
    position: relative;
    width: 100%;

    span {
      @include cardo-bold-inline;
    }

    &::before {
      background-image: url('../../design/img/stern-d.svg');
      background-position: center;
      background-size: 1em 1em;
      content: '';
      display: inline-block;
      height: 1em;
      margin-right: .5em;
      width: 1em;
    }
  }

  p {
    margin: 2em;

    span {
      @include cardo-bold-inline;
      color: $brown;
      text-transform: uppercase;
  }
  }
}

.imprint {
  span {
    @include cardo-bold-inline;
    color: $brown;
    font-size: .8em;
    text-transform: uppercase;
  }
}

.maps {
  padding: 1em 0;
}

.contact-page {
  .static-headline {
    @media screen and (max-width: 92em) {
      margin-left: auto;
      margin-right: auto;
      max-width: 15em;
    }
  }

  .content {
    position: relative;
    top: -5em;

    @media screen and (max-width: 92em) {
      top: 0;
    }
  }
}
