@keyframes scroll-middle {
  0% {
    transform: translateY(0);
  }

  60% {
    opacity: 0;
    transform: translateY(40px);
  }

  100% {
    opacity: 0;
    transform: translateY(40px);
  }
}

@keyframes scroll-bottom {
  0% {
    transform: translateY(0);
  }

  60% {
    opacity: 0;
    transform: translateY(80px);
  }

  100% {
    opacity: 0;
    transform: translateY(80px);
  }
}

.scroll {
  width: 100%;
}

.scroll-wrapper {
  align-items: flex-end;
  display: flex;
  flex: 1;
  margin-top: 4em;
  width: 4em;
}

.scroll-middle,
.scroll-bottom, {
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.scroll-middle {
  animation-name: scroll-middle;
}

.scroll-bottom {
  animation-name: scroll-bottom;
}
