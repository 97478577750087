.cc-window {
  @include kanit-extra-light-inline;
  text-transform: uppercase;
}

.cc-btn {
  align-items: center!important;
  background: none!important;
  border: 0!important;
  border-bottom: .1em solid $light!important;
  color: $light!important;
  cursor: pointer!important;
  display: inline-flex!important;
  margin: 0 0 0 1.5em!important;
  padding: 0 0 .2em!important;
  position: relative!important;
  transition: all .15s ease!important;

  &::before {
    background-image: url('../../public/img/stern.svg');
    background-position: left;
    background-repeat: no-repeat;
    background-size: 1.2em 1.2em;
    bottom: 0;
    content: '';
    left: -1.5em;
    min-height: 1.2em;
    position: absolute;
    top: 0;
    width: 1.5em;
  }

  &:hover {
    border-color: $beige;
    color: $beige;
  }
}
