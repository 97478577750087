/* === Cardo - 700 */
@font-face {
	font-family: 'Cardo';
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url("./../fonts//Cardo-Bold.eot");
	src: local("Cardo Bold"),
		local("Cardo-Bold"),
		url("./../fonts//Cardo-Bold.eot") format("embedded-opentype"),
		url("./../fonts//Cardo-Bold.woff") format("woff"),
		url("./../fonts//Cardo-Bold.woff2") format("woff2");
}

/* === Kanit - 200 */
@font-face {
	font-family: 'Kanit';
	font-style: normal;
	font-weight: 200;
	font-display: swap;
	src: url("./../fonts//Kanit-ExtraLight.eot");
	src: local("Kanit ExtraLight"),
		local("Kanit-ExtraLight"),
		url("./../fonts//Kanit-ExtraLight.eot") format("embedded-opentype"),
		url("./../fonts//Kanit-ExtraLight.woff") format("woff"),
		url("./../fonts//Kanit-ExtraLight.woff2") format("woff2");
}

/* === Kanit - 300 */
@font-face {
	font-family: 'Kanit';
	font-style: normal;
	font-weight: 300;
	font-display: swap;
	src: url("./../fonts//Kanit-Light.eot");
	src: local("Kanit Light"),
		local("Kanit-Light"),
		url("./../fonts//Kanit-Light.eot") format("embedded-opentype"),
		url("./../fonts//Kanit-Light.woff") format("woff"),
		url("./../fonts//Kanit-Light.woff2") format("woff2");
}

/* === Kanit - regular */
@font-face {
	font-family: 'Kanit';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src: url("./../fonts//Kanit-Regular.eot");
	src: local("Kanit Regular"),
		local("Kanit-Regular"),
		url("./../fonts//Kanit-Regular.eot") format("embedded-opentype"),
		url("./../fonts//Kanit-Regular.woff") format("woff"),
		url("./../fonts//Kanit-Regular.woff2") format("woff2");
}
