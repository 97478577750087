.events-table {
  border-collapse: collapse;
  width: 100%;

  th {
    @include kanit-extra-light;
    font-size: 1.8em;
    padding: .25rem .5rem 1.5rem;
    text-transform: uppercase;
  }

  td {
    padding: .5rem;
  }

  tbody {
    tr {
      &:nth-child(odd) {
        background-color: $event-row;
      }
    }
  }

  .event-left {
    text-align: left;
  }

  .event-right {
    text-align: right;
  }
}
