.preloader,
.intro {
  background-color: $white;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  padding: 4em 2em 2em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  scrollbar-color: $beige $light;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: $light;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $beige;
    border-radius: 6px;
    border: 3px solid $light;
  }
}

.preloader {
  background-image: url('../../design/Elemente/rings.svg');
  background-position: center;
  background-size: 5em;

  .intro-wrapper {
    opacity: 0;
  }
}

.intro {
  cursor: pointer;
  opacity: 1;
  transition: opacity .5s ease;
}

.intro-wrapper {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1500px;
  opacity: 1;
  transition: opacity .5s ease;
}

.intro-text {
  align-self: flex-end;
  margin-left: auto;
  max-width: 100%;
  width: 30em;

  img {
    display: inline-block;
    width: 100%;
  }

  @media screen and (max-width: 860px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 75%;
  }
}

.intro-logo {
  margin-left: 16em;
  max-width: 100%;
  width: 27em;

  object {
    display: inline-block;
    width: 100%;
  }

  @media screen and (max-width: 860px) {
    margin: auto;
    max-width: 75%;
  }
}

.intro-title,
.intro-subtitle {
  text-transform: uppercase;
}

.intro-title {
  @include kanit-extra-light-inline;
  font-size: 2.2em;
  margin: 1.5em 0 1em 10em;

  @media screen and (max-width: 860px) {
    margin-left: 30%;
    max-width: 75%;
  }
}
