$black: #000;
$white: #fff;

// 250/245/239
$light: #faf5ef;
// 163/152/142
$beige: #a3988e;
// 101/83/67
$brown: #655343;

$menu-bg: $brown;

$menu-fade-in-duration: .2s;
$header-height: 14em;

$dark-logo: #3a2b1e;
$danger: #d9534f;

$event-row: #f0eeec;
